<!--Vista para editar información que se muestra en el micrositio del evento-->
<template>
  <v-container fluid>
    <div>
      <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab href="#inscribed"> Inscritos </v-tab>
              <v-tab href="#registration"> Formulario de registro </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="inscribed">
                <inscribed :eventId="eventId" />
              </v-tab-item>
              <v-tab-item value="registration">
                <registration :eventId="eventId" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import Inscribed from './tabs/Inscribed';
import Registration from './tabs/Registration';

export default {
  components: {
    Inscribed,
    Registration
  },
  props: ['eventId'],
  data: () => ({
    tab: "inscribed"
  })
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}
</style>